import { Box } from "@mui/material";
import { Summary } from "@packages/store/models/Summary/SummaryModel";
import { useTranslation } from "react-i18next";

import { TableText } from "pages/Summary/styledComponents";

interface SummaryStatusrops {
  status: Summary["status"];
}

const StatusColor: Record<Summary["status"], string> = {
  completed: "#47B347",
  planned: "#562C8E",
  earlyCanceledByCustomer: "#FF9A03",
  earlyCanceledBySchool: "#FF9A03",
  lateCanceledByCustomer: "#FF6161",
  lateCanceledBySchool: "#FF6161",
  compensation: "#562C8E",
};

export const SummaryStatus = ({ status }: SummaryStatusrops): JSX.Element => {
  const color = StatusColor[status];
  const { t } = useTranslation();
  return (
    <TableText sx={{ color }}>
      <Box display="flex" gap="0.375rem" alignItems="center">
        <Box
          width="0.4375rem"
          height="0.4375rem"
          borderRadius="50%"
          bgcolor={color}
        />
        {t(`Summary:${status}`)}
      </Box>
    </TableText>
  );
};
