const ru = {
  Exercise: {
    Units: "Разделы",
    Blocks: "Блоки",
    Exercises: "Упражнения",
    Unit: "Раздел",
    Block: "Блок",
    Exercise: "Упражнение",
    Script: "Скрипт к видео",
    HideScript: "Скрыть скрипт",
    ShowScript: "Показать скрипт",
    LanguageSamples: "Язык. образцы",
    Extras: "Доп. материалы",
    ToolReload: "Обновить",
    ToolTranslate: "Перевести",
    ToolCheck: "Проверить",
    ToolNext: "Вперед",
    IsCheckedWarning: "Задание уже проверено",
    NotFilledWarning: "Вы не ответили на все вопросы",
    Translate: "Перевод",
    Vocabulary: "Лексика",
    NoTranslation: "Нет перевода",
    AddToDictionary: "Добавить в словарь",
    SelectedTranslationsWarning: "Нужно выбрать 1-3 перевода",
    TranslateWordFailed: "Не удалось перевести слово",
    AddWordsFailed: "Не удалось добавить слова",
    AddWordsSuccess: "Слова были успешно добавлены",
    AddedBefore: "Было добавлено ранее",
    EnterText: "Введите текст",
    Characters: "Символов",
    Words: "Слова",
    // TextSize: "Размер текста",
    TextSize: "Размер вашего текста:",
    WordsAtMinimum: "Cлов минимум",
    LearnWords: "Учить слова",
    Ok: "Ок",
    Expand: "Развернуть",
    Collapse: "Свернуть",
    Close: "Закрыть",
    ResizeTooltip: "Растянуть чтобы прочитать",
    ResetChangesTooltip: "Сбросить все изменения",
    NoChange: "Без изменений",
    CorrectAnswer: "Правильный ответ",
    DevTooltip:
      "Данный вид упражнения в разработке, для перехода на старый интерфейс",
    FollowLink: "Перейдите по ссылке",
    CreativeExercisePassed: "Ваше упражнение отправлено на проверку",
    CreativeExerciseVerified: "Ваша работа проверена. Нажмите",
    CreativeExerciseVerifiedLink: "сюда",
    DialogExerciseConfirmAnswer: "Подтвердить",
    DialogExerciseResetAnswer: "Перезаписать",
    DialogExerciseSelectAnswer: "Этот",
    DialogExerciseHelperLegend: "Как выполнять упражнение",
    DialogExerciseFinishHelperLegend: "Поздравляем!",
    DialogExerciseFirstHelper:
      "Мысленно выберите свой ответ из списка сообщений ниже, нажмите на микрофон и произнесите его.",
    DialogExerciseSecondHelper:
      "Отлично, система распознала вашу речь! Подтвердите свой ответ или перезапишите его.",
    DialogExerciseThirdHelper:
      "У вас закончились попытки голосового ввода, выберите один из вариантов ответа вручную.",
    DialogExerciseFinishHelper:
      // eslint-disable-next-line max-len
      "Вы выполнили упражнение. Вы можете прослушать весь диалог или перейти к следующему заданию, нажав на кнопку Проверки",
    DialogExerciseRecognitionError:
      "К сожалению система не смогла распознать ваш ответ, попробуйте ещё раз.",
    AudioRecorderText: "Нажмите, чтобы записать ({{count}}) сек",
    RadioLineHelperTextLeft: "Указаны не все правильные ответы. Найдите еще",
    RadioLineHelperTextRight: "правильных ответов.",
    NextDisabledWarning: "Проверьте упражнение",
    BrokenError:
      "Данное упражнение находится в разработке, выполните его в старом интерфейсе.",
    BrokenErrorLink: "Ссылка",
  },
  ResultsModals: {
    UnitTitle: "Результаты раздела {{name}}",
    BlockTitle: "Результаты блока {{name}}",
    Passed: "Верно: {{count}}",
    Wrong: "Ошибки: {{count}}",
    Skipped: "Пропущено: {{count}}",
    Blocks: "Блоки",
    Block: "Блок {{order}}: {{name}}",
    Exercises: "Упражнения",
    Exercise: "Упражнение {{order}}: {{name}}",
    Repeat: "Повторить",
    Continue: "Продолжить",
    DontShowAgain:
      "Больше не показывать это окно (включить обратно можно в настройках профиля)",

    TestUnitTitle: "Поздравляем! Вы закончили {{order}} этап тестирования",
    TestUnitResultTitle: "Ваш результат -",
    TestUnit: "Раздел {{order}}. {{name}}",
    TestUnitTotalProgress: "Общий прогресс",
    TestUnitResults: "Результаты",
    TestUnitNext: "Начать следующий этап",
    TestUnitScoredPoints_zero: "{{count}}/{{total}} баллов",
    TestUnitScoredPoints_one: "{{count}}/{{total}} балл",
    TestUnitScoredPoints_few: "{{count}}/{{total}} балла",
    TestUnitScoredPoints_many: "{{count}}/{{total}} баллов",
  },
  TestingCourseResult: {
    ModalTitle: "Результаты теста",
    Confirm: "Перейти к результатам",
    ModalBodyEntrance:
      "Уважаемый {{fullName}}, Вам рекомендован курс уровня по шкале CEFR: {{cefrLevel}}.",
    ModalBodyFinal:
      'Уважаемый {{fullName}}, Вы закончили тестирование, набрав $t(TestingCourseResult:courseMark, {"count": {{courseMark}} }) из {{maxMark}}.\nВаш результат - {{percentage}}%.',
    ModalBodyDefault:
      "Уважаемый {{fullName}}, Вы успешно завершили тестирование.",
    courseMark_zero: "{{count}} баллов",
    courseMark_one: "{{count}} балл",
    courseMark_few: "{{count}} балла",
    courseMark_many: "{{count}} баллов",
  },
};

export default ru;
