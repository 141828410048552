import { Lesson } from "@packages/store/models/Lesson/LessonModel";

export const formatLesson = (lesson: Lesson) => {
  return {
    id: lesson.id,
    index: lesson.index,
    status: lesson.status,
    startDate: lesson.startAtLocal,
    endDate: lesson.endAtLocal,
    group: {
      id: lesson.groupId,
      name: lesson.groupName,
    },
    customerCompany: {
      id: lesson.customerCompany?.id,
      name: lesson.customerCompany?.name,
    },
    teacher: lesson.teacher?.fullName,
    technicalCancellation: lesson.technicalCancellation,
    duration: lesson.exactDuration,
    actualUnitId: lesson.actualUnitId,
    typeShort: lesson.typeShort,
  };
};

export type FormattedLesson = ReturnType<typeof formatLesson>;
