import { Box } from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";
import { Lesson } from "@packages/store/models/Lesson/LessonModel";
import { PivotReportSchedule } from "@packages/store/services/Api";
import { getLessonsTotalDurationHours } from "@packages/store/utils/helpers";

import { GroupStatistics } from "components/GroupStatistics";

const getGroupClassPeriodDates = (group: LearningGroup) => {
  const { from, to } = group.lessonsPeriod;
  if (from && !to) {
    return "-";
  }
  if (!from || !to) return "";
  return `${format(from, "dd.MM.yyyy")} - ${format(to, "dd.MM.yyyy")}`;
};

export interface AttendanceReportsGroupStatisticsProps {
  filteredLessons?: Lesson[];
  completedLessons?: Lesson[];
  notPerformedToPayLessons: Lesson[];
  schedules?: PivotReportSchedule[];
  group?: LearningGroup;
  totalHours?: number;
}

export const AttendanceReportsGroupStatistics = observer(
  ({
    group,
    schedules,
    completedLessons,
    notPerformedToPayLessons,
    filteredLessons = [],
    totalHours = 0,
  }: AttendanceReportsGroupStatisticsProps): JSX.Element => {
    const { t } = useTranslation();

    if (!group) {
      return <Box />;
    }

    const { statistics } = group;

    const getWeekDay = (day: string) => {
      return t(`ShortWeekDay:${day}`);
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const formatSchedules = (schedules: PivotReportSchedule[]) => {
      return schedules.map((schedule) => {
        const { day = "", startTime = "", endTime = "" } = schedule ?? {};
        const weekDay = getWeekDay(day);
        return `${weekDay} ${startTime} - ${endTime}`;
      });
    };

    const completedHours = getLessonsTotalDurationHours(filteredLessons ?? []);
    const allCompletedHours = getLessonsTotalDurationHours(
      completedLessons ?? []
    );

    // TODO https://jira.jetclass.ru/browse/JS-1967
    const notPerformedToPayHours = notPerformedToPayLessons.reduce(
      (total, lesson) => {
        return total + lesson.exactDuration;
      },
      0
    );

    const leftHours = (totalHours ?? 0) - allCompletedHours;

    return (
      <GroupStatistics
        groupName={group.name ?? ""}
        teacherName={statistics.teacherName}
        schedule={formatSchedules(schedules ?? [])}
        courseName={statistics.courses}
        classPeriod={getGroupClassPeriodDates(group)}
        totalHours={totalHours}
        completedHours={completedHours}
        leftHours={leftHours}
        notPerformedToPayHours={notPerformedToPayHours}
      />
    );
  }
);
