/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { camelCase } from "change-case";
import i18n from "i18next";
import { set, get } from "lodash";

// import { Lesson } from "models/Lesson/LessonModel";
// import { theme } from "themes/default";
import { emptyModule } from "../constants/constants";
import { Course } from "../models/Course/CourseModel";
import { Translation } from "../models/Translation/TranslationModel";
import { localeEn, localeRu } from "./dateFnsLocale";

export const isDev: boolean = process.env.NODE_ENV === "development";

export function getCurrentLocale(): Locale {
  if (i18n.language === "ru") return localeRu;
  return localeEn;
}

export const flatPick = (object: any, paths: string[]) => {
  const newObject = {};
  paths.forEach((path) => set(newObject, path, get(object, path)));
  return newObject;
};

export const addEmptyModule = (data: Course[]): Course[] => {
  const newModules =
    data.length % 2 === 0 ? data : [...data, { ...emptyModule }];

  return newModules as Course[];
};

export const isEmpty = (obj: any) => {
  if (!obj) return true;
  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
};

export const getLocalName = (
  data: Translation[] = [],
  locale = "ru"
): string => {
  const dataByLocale = data.find((item) => locale === item.locale);

  return dataByLocale?.name || "";
};

export const convertNumberToString = (value: any) => {
  if (typeof value === "number") {
    return value.toString();
  }
  return value;
};

export const convertStringToDate = (value: string) => {
  if (typeof value === "string" && value.match(/\d{4}-[01]\d-[0-3]/)) {
    return new Date(value);
  }
  return value;
};

export const deflateObject = (object: any) => {
  const result = {} as any;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in object) {
    const newKey = key.endsWith("Ids") ? key.replace("Ids", "s") : key;
    // eslint-disable-next-line no-underscore-dangle
    if (object[key]?.__typename && object[key]?.id) {
      result[newKey] = convertNumberToString(object[key].id);
    } else {
      result[newKey] = object[key];
    }
    if (key === "id") {
      result[key] = convertNumberToString(object[key]);
    }
    if (Array.isArray(object[key])) {
      const notDeflate = object[key][0]?.__notDeflate;
      result[newKey] = notDeflate
        ? object[key]
        : object[key].map((item: any) =>
            item.id ? String(item.id) : convertNumberToString(item)
          );
    }
    result[newKey] = convertStringToDate(result[newKey]);
  }
  return result;
};

// check if string is kebab-case
export const isKebabCase = (str: string): boolean =>
  str.match(/^[a-z0-9-]+$/g) !== null;

// convert kebab-case to camelCase
export const kebabToCamelCase = (str: string): string =>
  str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

export const removeEmptyValues = (obj: any): any => {
  if (obj instanceof Array) {
    return obj
      .filter((v) => v !== null && v !== undefined)
      .map((v) => (v instanceof Object ? removeEmptyValues(v) : v));
  }

  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, v]) => v !== null && v !== undefined)
      .map(([k, v]) => [
        k,
        v instanceof Object && !(v instanceof Date) ? removeEmptyValues(v) : v,
      ])
  );
};

export const mergeHelper = (store: any, data: any): any => {
  // eslint-disable-next-line consistent-return
  const merge = (data: any): any => {
    try {
      if (!data || typeof data !== "object") return data;
      if (Array.isArray(data)) return data.map(merge);

      // eslint-disable-next-line no-underscore-dangle
      if (typeof data === "object" && !data.__typename) {
        return merge(Object.values(data));
      }

      const snapshot: any = {};
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in data) {
        snapshot[key] = merge(data[key]);
      }
      // eslint-disable-next-line no-underscore-dangle
      const currentStore = store[data.__typename];
      if (!currentStore) {
        console.log("store not exist", data.__typename);
        // eslint-disable-next-line no-underscore-dangle
        return null;
      }
      const deflatedObject = deflateObject(data);
      if (!store?.[data.__typename]?.addItem)
        console.log("!!!!", data.__typename);
      store[data.__typename].addItem(removeEmptyValues(deflatedObject));
    } catch (error) {
      console.log(error);
    }
  };

  return merge(data);
};

export const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min)) + min + 1;
};

export const getRandomElementFromArray = (colors: string[]): string => {
  const number = getRandomNumber(0, colors.length - 1);
  return colors[number];
};
// export const getCardType = (lesson: Lesson): CardType => {
//   const { webinarRecordLink, currentStatus, webinarRoomLink } = lesson;

//   if (webinarRecordLink && currentStatus === "completed") {
//     return { icon: "camera", color: theme.palette.warning.dark };
//   }

//   if (!webinarRecordLink && currentStatus === "planned" && webinarRoomLink) {
//     return { icon: "elementary", color: theme.palette.info.light };
//   }

//   return { icon: "users", color: theme.palette.success.dark };
// };

export const addDaysToDate = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const addMonthsToDate = (date: Date, months: number): Date => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
};

const createRuWordDeclensionEndings = (
  titles: string[],
  number: number | undefined
) => {
  const cases = [2, 0, 1, 1, 1, 2];

  if (!number) {
    return titles[2];
  }

  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

const createEnWordDeclensionEndings = (
  titles: string[],
  number: number | undefined
) => {
  if (number === 1) {
    return titles[0];
  }

  return titles[1];
};

export const wordDeclensionEndings = (
  word: string,
  number: number | undefined
) => {
  if (word && !!word.length) {
    const currentLocale = getCurrentLocale();
    if (currentLocale.code === "ru") {
      return createRuWordDeclensionEndings(
        [
          i18n.t(`Module:${word}_one`),
          i18n.t(`Module:${word}_two`),
          i18n.t(`Module:${word}_few`),
        ],
        number
      );
    }
    return createEnWordDeclensionEndings(
      [i18n.t(`Module:${word}_one`), i18n.t(`Module:${word}_few`)],
      number
    );
  }
  return null;
};

export const setMidnight = (date: Date) => {
  const midnight = new Date(date);
  midnight.setHours(0, 0, 0, 0);
  return midnight;
};

export const transformObjectKeys = (obj: any) => {
  if (obj === null || obj === undefined) {
    return obj;
  }

  let newObj = obj;

  if (typeof newObj === "object") {
    if (obj instanceof Array) {
      newObj = obj.map(transformObjectKeys);
    } else {
      newObj = {};

      Object.keys(obj).forEach((key) => {
        if (key === "id") {
          newObj[camelCase(key)] = transformObjectKeys(String(obj[key]));
        } else {
          newObj[camelCase(key)] = transformObjectKeys(obj[key]);
        }
      });
    }
  }

  return newObj;
};

export const hasEmptyValue = (obj: any): boolean => {
  if (obj instanceof Array) {
    return (
      isEmpty(obj) ||
      obj.some((v) => (v instanceof Object ? hasEmptyValue(v) : false))
    );
  }

  return (
    isEmpty(obj) ||
    Object.entries(obj).some(([, v]) =>
      v ? (v instanceof Object ? hasEmptyValue(v) : false) : true
    )
  );
};
