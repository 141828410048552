/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  types,
  SnapshotOut,
  Instance,
  getRoot,
  getSnapshot,
  IAnyModelType,
  tryReference,
} from "mobx-state-tree";

import { RootStore } from "..";
import { withTranslation } from "../extensions/withTranslation";
import { KnowledgeLevelModel } from "../KnowledgeLevel/KnowledgeLevel";
import { Lesson } from "../Lesson/LessonModel";
import { Passing } from "../Passing/Passing";
import { Unit } from "../Unit/UnitModel";
import { addMonthsToDate } from "../../utils/basic";

import { TranslationModel } from "../Translation/TranslationModel";
import { ContentImageModel } from "../ContentImage/ContentImage";
import { ClassGroupModel } from "../ClassGroup/ClassGroup";

type UnitsProps = {
  all?: boolean;
  completed?: boolean;
};

export const CourseModel = types
  .model("CourseModel", {
    id: types.identifier,
    available: types.maybeNull(types.boolean),
    code: types.maybeNull(types.string),
    isForTesting: types.optional(types.boolean, false),
    // * Тут некрасиво получается, два этих поля приходят по запросу /api/v2/courses
    // * хотя логически это относится к пассингам
    markPercent: types.maybeNull(types.number),
    passingPercent: types.maybeNull(types.number),

    picture: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.Date),
    translationsAttributes: types.optional(types.array(TranslationModel), []),
    knowledgeLevel: types.maybeNull(
      types.reference(types.late((): IAnyModelType => KnowledgeLevelModel))
    ),
    isHide: types.maybeNull(types.boolean),
    publisher: types.maybeNull(types.string),
    languageId: types.maybeNull(types.string),
    knowledgeAreaId: types.maybeNull(types.string),
    deletedAt: types.maybeNull(types.Date),
    maxMark: types.maybeNull(types.number),
    distributorId: types.maybeNull(types.string),
    pictureId: types.maybeNull(types.number),
    timeLimit: types.maybeNull(types.number),
    passingThreshold: types.union(
      types.number,
      types.string,
      types.undefined,
      types.null
    ),
    passingThresholdPercent: types.maybeNull(types.number),
    order: types.maybeNull(types.number),
    childrenListUpdatedAt: types.maybeNull(types.Date),
    showInCourseList: types.maybeNull(types.boolean),
    duration: types.maybeNull(types.number),
    tags: types.optional(types.array(types.string), []),
    name: types.maybeNull(types.string),
    shortName: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    lessonsPerUnit: types.maybeNull(types.number),
    startDate: types.maybeNull(types.Date),
    contentImages: types.optional(types.array(ContentImageModel), []),
  })
  .views((self) => ({
    getUnits({ all = true, completed = false }: UnitsProps = {}): Unit[] {
      const rootStore = getRoot<RootStore>(self);
      const units = rootStore.unit.items.filter((unit) => {
        // Возможно курс ещё не подгрузился поэтому нужно проверять ссылку
        const course = tryReference(() => unit?.course);

        if (course) {
          return unit?.course?.id === self.id;
        }

        return false;
      });
      return all
        ? units
        : units.filter((unit) => unit.isCompleted === completed);
    },
    get groupStatus(): string | null | undefined {
      const { learningGroup } = getRoot<RootStore>(self);
      const group = learningGroup.items.find((group) =>
        group.courses.find((course: { id: string }) => course.id === self.id)
      );
      return group?.status;
    },
    get lessons(): Lesson[] {
      return getRoot<RootStore>(self).lesson.items.filter(
        (lesson) => lesson?.course?.id === self.id
      );
    },
    get passing(): Passing | undefined {
      return getRoot<RootStore>(self).passing.items.find(
        (passing) => getSnapshot(passing)?.course === self.id
      );
    },
    get hasGroup(): boolean {
      const { learningGroup } = getRoot<RootStore>(self);
      const group = learningGroup.items.find((group) =>
        group.courses.find((course: { id: string }) => course.id === self.id)
      );
      return !!group;
    },
  }))
  .views((self) => ({
    get sortedUnits(): Unit[] {
      return self.getUnits().sort((a, b) => Number(a.order) - Number(b.order));
    },
    get totalProgress(): number {
      return (
        (self.isForTesting
          ? self.passing?.passingTestPercent
          : self.passing?.passingPercent) ?? 0
      );
    },
    get resultProgress(): number {
      return (
        (self.isForTesting
          ? self.passing?.markTestPercent
          : self.passing?.markPercent) ?? 0
      );
    },
  }))
  .views((self) => ({
    get firstUnit(): Unit {
      return self.sortedUnits[0];
    },
    get lastUnit(): Unit {
      return self.sortedUnits[self.sortedUnits.length - 1];
    },
    getNextUnit(unitId: string): Unit | null {
      const index = self.sortedUnits.findIndex(
        ({ id }) => id === unitId
      ) as number;

      return self.sortedUnits[index + 1] ?? null;
    },
    get availableUnitsForTesting(): Unit[] {
      return self.sortedUnits.filter((unit, i, arr) => {
        if (i === 0) {
          return true;
        }

        if (unit.state !== "notStarted") {
          return true;
        }

        return arr[i - 1].state === "finished";
      });
    },
  }))
  .views((self) => ({
    get unitsProgress(): { completed: number; total: number } {
      const unitsCompletedNumber = self
        ?.getUnits()
        ?.reduce(
          (total: number, unit: Unit) => total + unit.completedLessonsHours,
          0
        );
      const unitsTotalNumber = self
        ?.getUnits()
        ?.reduce((total: number, unit: Unit) => total + unit.lessonsHours, 0);
      return { completed: unitsCompletedNumber, total: unitsTotalNumber };
    },
    get classesProgress(): { completed: number; total: number } {
      const lessonsCompleted = self.lessons.filter(
        (lesson) => lesson.isCompleted && lesson.compensation !== true
      );
      const lessonsIsNotCompleted = self.lessons.filter(
        (lesson) =>
          lesson.currentStatus === "planned" && lesson.compensation !== true
      );
      const totalLessonsCount =
        lessonsIsNotCompleted.length + lessonsCompleted.length;
      return { completed: lessonsCompleted.length, total: totalLessonsCount };
    },
    get classesTimeProgress(): { completed: number; total: number } {
      const allLessons = self.lessons.filter(
        ({ compensation }) => compensation !== true
      );
      const completedLessons = allLessons.filter(
        ({ isCompleted }) => isCompleted
      );
      const plannedLessons = self.lessons.filter(
        ({ currentStatus }) => currentStatus === "planned"
      );
      const totalLessons = [...completedLessons, ...plannedLessons];

      const completedTime = completedLessons.reduce(
        (time, { duration }) => time + duration,
        0
      );
      const totalTime = totalLessons.reduce(
        (time, { duration }) => time + duration,
        0
      );
      return { completed: completedTime, total: totalTime };
    },
    // TODO этот метод не верен тем, что групп несколько может быть и уроки надо брать только активной группы
    get finishDate(): Date | undefined | null {
      if (self.lessons.length > 0 || !self.startDate || !self.duration) {
        return self.lessons
          ?.filter((lesson) => lesson.isActiveLearningGroup)
          ?.slice()
          .sort((a, b) => Number(b?.endAtLocal) - Number(a?.endAtLocal))[0]
          ?.endAtLocal;
      }
      return addMonthsToDate(self.startDate, self.duration);
    },
    isUnitAvailableForTesting(unitId: string): boolean {
      return self.availableUnitsForTesting.some(({ id }) => id === unitId);
    },
    get contentImageLongForExercise() {
      return self.contentImages.find((image) => image.isLongForExercise);
    },
    get contentImageForPreview() {
      return self.contentImages.find((image) => image.isForPreview);
    },
  }))
  .extend(withTranslation);

type CourseType = Instance<typeof CourseModel>;
export interface Course extends CourseType {}
type CourseSnapshotType = SnapshotOut<typeof CourseModel>;
export type CourseSnapshot = CourseSnapshotType;
