import { Lesson } from "../../models/Lesson/LessonModel";

export function getLessonsTotalDurationHours(lessons: Lesson[]): number {
  return lessons
    .filter((lesson) => !lesson.compensation)
    .reduce((total, lesson) => {
      return total + lesson.exactDuration;
    }, 0);
}

export function getNotPerformedToPayLessons(lessons: Lesson[]): Lesson[] {
  const e = lessons.filter(
    (lesson) => lesson.status === "earlyCanceledByCustomer"
  );
  const c = lessons.filter(
    (lesson) =>
      lesson.status === "earlyCanceledBySchool" && lesson.technicalCancellation
  );

  return [...e, ...c].slice(2);
}
