import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ProcessModeSwitchProps {
  checked: boolean;
  onChange: (value: boolean) => void;
}

export const ProcessModeSwitch = ({
  checked,
  onChange,
}: ProcessModeSwitchProps): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mb: 4 }}>
      <FormControlLabel
        control={
          <Switch checked={checked} onChange={(_, value) => onChange(value)} />
        }
        label={
          <Typography variant="regularText">
            {t("Classes:ProcessMode")}
          </Typography>
        }
      />
    </Box>
  );
};
