import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { utils, writeFile, Range } from "xlsx-js-style";

import { useStores } from "@packages/store";
import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";
import { Lesson } from "@packages/store/models/Lesson/LessonModel";
import {
  PivotReport,
  PivotReportStatistics,
} from "@packages/store/services/Api";
import { getNotPerformedToPayLessons } from "@packages/store/utils/helpers";

import { ButtonWithIcon } from "components/ButtonWithIcon";
import {
  getLessonsTotalDurationHours,
  getPivotStudentAttendance,
  getPivotStudentLessonHours,
  getPivotTableMonths,
} from "utils/helpers";

import { getEmptyBorderedCells, sheetStyle } from "./sheetStyle";
import { filterLessonsByDateRange } from "../../helpers";
import { DateRange } from "../../types";

export interface ReportsAttendanceDownloadProps {
  groups: LearningGroup[];
  dateRange?: DateRange;
  onlyTeachersLessons: boolean;
}

export const ReportsAttendanceDownload = observer(
  ({
    groups,
    dateRange,
    onlyTeachersLessons,
  }: ReportsAttendanceDownloadProps): JSX.Element => {
    const { t } = useTranslation();

    const { auth } = useStores();
    const showOnlyTeacherId =
      onlyTeachersLessons && auth.user?.isTeacher ? auth.user?.id : undefined;

    const getWeekDay = (day: string) => {
      return t(`ShortWeekDay:${day}`);
    };

    const getStudentLessonHoursColor = (
      ids: number[],
      lesson: Lesson,
      notPerformedToPayLessons: Lesson[]
    ) => {
      const hours = getPivotStudentLessonHours(
        ids,
        lesson,
        notPerformedToPayLessons
      );
      if (hours === "X" || hours === "ⓧ") {
        return "FF9A03";
      }
      const { compensation } = lesson;
      if (compensation) {
        return "5783F1";
      }
      const attendance = getPivotStudentAttendance(ids, lesson);
      if (!attendance) {
        return "FF9A03";
      }
      if (!attendance.attended || lesson.compensation) {
        return "FF2E00";
      }
      return "000000";
    };

    const getLessonDuration = (lesson: Lesson) => {
      const { compensation } = lesson;
      const duration = compensation ? 0 : lesson.exactDuration ?? 0;
      return duration;
    };

    const getFilteredLessonsToEnd = (items: Lesson[]) => {
      const { endDate } = dateRange ?? {};
      return filterLessonsByDateRange(items, { endDate });
    };

    const getCompletedLessons = (items: Lesson[]) => {
      return items.filter(({ isCompleted }: Lesson) => isCompleted);
    };

    const formatGroupSheet = (group: LearningGroup) => {
      const { pivotReport = {}, statistics = {} } = group ?? {};

      const { students, lessons } = pivotReport as PivotReport;
      const { totalHours, courses, teacherName, schedules } =
        statistics as PivotReportStatistics;

      const filteredLessons = filterLessonsByDateRange(
        getCompletedLessons(lessons),
        dateRange
      );

      const finishedLessons = getFilteredLessonsToEnd(
        getCompletedLessons(lessons)
      );

      // В статистику фильтровать по преподу не нужно, а в таблицу нужно
      const teacherLessons = filteredLessons.filter((lesson) =>
        showOnlyTeacherId ? lesson.teacherId === showOnlyTeacherId : true
      );
      // TODO https://jira.jetclass.ru/browse/JS-1967
      const notPerformedToPayLessons = group?.customerCompany?.lessonsAutoCancel
        ? getNotPerformedToPayLessons(
            filterLessonsByDateRange(lessons, dateRange)
          )
        : [];
      const lessonsToTable = [
        ...teacherLessons,
        ...notPerformedToPayLessons,
      ].sort((first, second) => {
        const firstDay = first.day?.getTime() ?? 0;
        const secondDay = second.day?.getTime() ?? 0;
        return firstDay < secondDay ? -1 : 1;
      });

      if (!lessonsToTable.length || !students.length) {
        return [];
      }

      const months = getPivotTableMonths(
        lessonsToTable.map((lesson) => lesson.day) as Date[]
      );

      const completedHours = getLessonsTotalDurationHours(lessonsToTable ?? []);
      const allCompletedHours = getLessonsTotalDurationHours(
        finishedLessons ?? []
      );
      const leftHours = (totalHours ?? 0) - allCompletedHours;
      const notPerformedToPayHours = notPerformedToPayLessons.reduce(
        (total, lesson) => {
          return total + lesson.exactDuration;
        },
        0
      );

      const sheet = [];
      sheet.push([]);
      sheet.push([
        {
          v: t("AttendancePivotTable:GroupSchedule", {
            group: group.name,
          }),
          t: "s",
          s: sheetStyle.groupName,
        },
        ...getEmptyBorderedCells(21),
      ]);
      sheet.push([]);
      sheet.push([
        {},
        {
          v: t("AttendancePivotTable:Course", {
            course: courses,
          }),
          t: "s",
          s: sheetStyle.metaInfo,
        },
      ]);
      sheet.push([
        {},
        {
          v: t("AttendancePivotTable:Total", {
            hours: totalHours.toFixed(2),
          }),
          t: "s",
          s: sheetStyle.metaInfo,
        },
      ]);
      sheet.push([
        {},
        {
          v: t("AttendancePivotTable:CompletedPeriod", {
            hours: completedHours.toFixed(2),
          }),
          t: "s",
          s: sheetStyle.metaInfo,
        },
      ]);
      sheet.push([
        {},
        {
          v: t("AttendancePivotTable:NotPerformedToPayPeriod", {
            hours: notPerformedToPayHours.toFixed(2),
          }),
          t: "s",
          s: sheetStyle.metaInfo,
        },
      ]);
      sheet.push([
        {},
        {
          v: t("AttendancePivotTable:LeftPeriod", {
            hours: leftHours.toFixed(2),
          }),
          t: "s",
          s: sheetStyle.metaInfo,
        },
      ]);
      sheet.push([]);
      sheet.push([
        {},
        {
          v: t("AttendancePivotTable:Teacher"),
          t: "s",
          s: sheetStyle.teacherLabel,
        },
        {
          v: t("AttendancePivotTable:Days"),
          t: "s",
          s: sheetStyle.scheduleDayLabel,
        },
        ...getEmptyBorderedCells(3),
        {
          v: t("AttendancePivotTable:Time"),
          t: "s",
          s: sheetStyle.scheduleTimeLabel,
        },
        ...getEmptyBorderedCells(3),
      ]);
      sheet.push([
        {},
        {
          v: teacherName ?? "",
          t: "s",
          s: sheetStyle.teacher,
        },
        {
          v: schedules.map((time) => getWeekDay(time.day)).join(", "),
          t: "s",
          s: sheetStyle.scheduleDay,
        },
        ...getEmptyBorderedCells(3),
        {
          v: schedules
            .map((time) => `${time.startTime} - ${time.endTime}`)
            .join(", "),
          t: "s",
          s: sheetStyle.scheduleTime,
        },
        ...getEmptyBorderedCells(3),
      ]);
      sheet.push([
        {},
        {
          v: "",
          t: "s",
          s: sheetStyle.filler,
        },
      ]);

      sheet.push([
        ...getEmptyBorderedCells(1),
        {
          v: t("AttendancePivotTable:Lesson"),
          t: "s",
          s: sheetStyle.lessonsLabel,
        },
        ...lessonsToTable.map((lesson) => ({
          v: lesson.index ?? "",
          t: "n",
          s: sheetStyle.lessons,
        })),
      ]);

      let monthList: Array<string | number> = [];
      months.forEach((month) => {
        monthList = [...monthList, month.month];
        monthList = [...monthList, ...new Array(month.columns - 1).fill("")];
      });
      sheet.push([
        ...getEmptyBorderedCells(1),
        {
          v: t("AttendancePivotTable:Month"),
          t: "s",
          s: sheetStyle.monthesLabel,
        },
        ...monthList.map((month) => ({
          v: month,
          t: "n",
          s: sheetStyle.monthes,
        })),
      ]);

      sheet.push([
        ...getEmptyBorderedCells(1),
        {
          v: t("AttendancePivotTable:Date"),
          t: "s",
          s: sheetStyle.daysLabel,
        },
        ...lessonsToTable.map((lesson) => ({
          v: lesson.day?.getDate() ?? "",
          t: "n",
          s: sheetStyle.days,
        })),
      ]);

      sheet.push([
        ...getEmptyBorderedCells(1),
        {
          v: t("AttendancePivotTable:Hours"),
          t: "s",
          s: sheetStyle.hoursLabel,
        },
        ...lessonsToTable.map((lesson) => ({
          v: getLessonDuration(lesson),
          t: "n",
          s: sheetStyle.hours,
          z: "0.00",
        })),
      ]);

      students.forEach((student, index) => {
        sheet.push([
          {
            v: index + 1,
            t: "n",
            s: sheetStyle.studentNumber,
          },
          {
            v: student.name,
            t: "s",
            s: sheetStyle.studentName,
          },
          ...lessonsToTable.map((lesson) => {
            const hours = getPivotStudentLessonHours(
              student.ids,
              lesson,
              notPerformedToPayLessons
            );
            const isNumber = !Number.isNaN(+hours);
            return {
              v: hours,
              t: isNumber ? "n" : "s",
              s: {
                ...sheetStyle.studentAttendance,
                font: {
                  ...sheetStyle.studentAttendance.font,
                  color: {
                    rgb: getStudentLessonHoursColor(
                      student.ids,
                      lesson,
                      notPerformedToPayLessons
                    ),
                  },
                },
                ...(!isNumber && {
                  alignment: {
                    horizontal: "center",
                  },
                }),
              },
              ...(isNumber && { z: "0.00" }),
            };
          }),
        ]);
      });

      sheet.push([
        {},
        {},
        ...lessonsToTable.map((lesson) => {
          return {
            v: lesson.comment ?? "",
            t: "s",
          };
        }),
      ]);

      return sheet;
    };

    const handleDownload = () => {
      const wb = utils.book_new();

      let sheet: unknown[][] = [];
      const merges: Range[] = [];

      let headerOffset = 1;
      groups.forEach((group) => {
        const formattedSheet = formatGroupSheet(group);
        if (!formattedSheet.length) {
          return;
        }
        sheet = [...sheet, ...formattedSheet];
        merges.push({
          s: { c: 0, r: headerOffset },
          e: { c: 21, r: headerOffset },
        });
        const timeOffset = 7;
        merges.push({
          s: { c: 2, r: headerOffset + timeOffset },
          e: { c: 5, r: headerOffset + timeOffset },
        });
        merges.push({
          s: { c: 6, r: headerOffset + timeOffset },
          e: { c: 9, r: headerOffset + timeOffset },
        });
        merges.push({
          s: { c: 2, r: headerOffset + timeOffset + 1 },
          e: { c: 5, r: headerOffset + timeOffset + 1 },
        });
        merges.push({
          s: { c: 6, r: headerOffset + timeOffset + 1 },
          e: { c: 9, r: headerOffset + timeOffset + 1 },
        });
        headerOffset += formattedSheet.length;
      });

      const ws = utils.aoa_to_sheet(sheet);
      ws["!merges"] = merges;
      ws["!cols"] = [
        {
          wpx: 30,
        },
        {
          width: 40,
        },
      ];

      utils.book_append_sheet(
        wb,
        ws,
        t("AttendancePivotReportDownload:Sheet") ?? ""
      );

      writeFile(wb, "Attendance report.xlsx");
    };

    return (
      <ButtonWithIcon
        title={t("ReportsAttendance:Download")}
        onClick={handleDownload}
      />
    );
  }
);
